import styled from "styled-components";

interface Props {
  link: string;
  name: string;
  baseColor: string;
  hoverColor: string;
}

function ContactIcon(props: Props) {
  const { link, name, baseColor, hoverColor } = props;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <IconImage className={name} baseColor={baseColor} hoverColor={hoverColor}/>
    </a>
  );
}

const IconImage = styled.i<{baseColor: string, hoverColor: string}>`
  color: ${props => props.baseColor};
  font-size: 4.8rem;
  transition: all 0.2s ease-in-out;
  margin: 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${props => props.hoverColor};
  }
`;

export default ContactIcon;

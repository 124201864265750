const small = "600px";
const medium = "800px";
const large = "1100px";

const breakpoints = {
  small,
  medium,
  large,
};

export default breakpoints;

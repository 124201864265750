import styled from "styled-components";

export const Button = styled.button<{
  backgroundColor: string;
  textColor: string;
  height: number;
  width: number;
}>`
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  border: none;
  border-radius: 2.5rem;
  box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
    6px 6px 10px rgba(0, 0, 0, 0.2);
  color: ${(props) => props.textColor};
  cursor: pointer;
  display: flex;
  height: ${(props) => props.height}px;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  width: ${(props) => props.width}px;

  &:hover {
    box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
      6px 6px 10px rgba(0, 0, 0, 0.2);
    opacity: 0.9;
  }

  &:active {
    box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5),
      inset 8px 8px 16px rgba(0, 0, 0, 0.1);
    opacity: 1;
  }
`;

import { useState, ChangeEvent, MouseEvent } from "react";
import { validateAndSendMessage } from "./utils";
import { Message, blankMessage } from "./fixtures";
import ContactFormView from "./ContactFormView";

function ContactFormContainer() {
  const [message, setMessage] = useState<Message>(blankMessage);

  function handleChange(
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    setMessage({ ...message, [e.target.name]: e.target.value });
  }

  function handleSubmit(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    validateAndSendMessage(message, setMessage);
  }

  function handleClear(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    setMessage(blankMessage);
  }

  return (
    <ContactFormView
      message={message}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleClear={handleClear}
    />
  );
}

export default ContactFormContainer;

import styled from "styled-components";
import { ContactIconData, contactIconsList } from "./fixtures";
import ContactIcon from "./ContactIcon";

interface Props {
  baseColor: string;
  hoverColor: string;
}

function ContactIcons(props: Props) {
  const { baseColor, hoverColor } = props;

  return (
    <IconsWrapper>
      {contactIconsList.map((iconData: ContactIconData) => (
        <ContactIcon
          link={iconData.link}
          name={iconData.name}
          baseColor={baseColor}
          hoverColor={hoverColor}
        />
      ))}
    </IconsWrapper>
  );
}

const IconsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export default ContactIcons;

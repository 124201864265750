import htmlLogo from "images/skillIcons/html.svg";
import cssLogo from "images/skillIcons/css.svg";
import javascriptLogo from "images/skillIcons/javascript.svg";
import typescriptLogo from "images/skillIcons/typescript.svg";
import reactLogo from "images/skillIcons/react.svg";
import reactNativeLogo from "images/skillIcons/react-native.svg";
import reduxLogo from "images/skillIcons/redux.svg";
import pythonLogo from "images/skillIcons/python.svg";
import djangoLogo from "images/skillIcons/django.svg";
import nodeJsLogo from "images/skillIcons/node.svg";
import knexLogo from "images/skillIcons/knex.svg";
import sqlLogo from "images/skillIcons/sql.svg";
import postgreSqlLogo from "images/skillIcons/postgreSQL.svg";
import sqliteLogo from "images/skillIcons/sqlite3.svg";
import mongodbLogo from "images/skillIcons/mongodb.svg";
import bigtableLogo from "images/skillIcons/bigtable.svg";
import firebaseLogo from "images/skillIcons/firebase.svg";
import dockerLogo from "images/skillIcons/docker.svg";
import gitLogo from "images/skillIcons/git.svg";
import jestLogo from "images/skillIcons/jest.svg";
import pytestLogo from "images/skillIcons/pytest.svg";
import d3Logo from "images/skillIcons/d3.svg";
import lessLogo from "images/skillIcons/less.svg";
import sassLogo from "images/skillIcons/sass.svg";
import materialUiLogo from "images/skillIcons/material-ui.svg";
import agileLogo from "images/skillIcons/agile.svg";
import angularLogo from "images/skillIcons/angular.svg";
import drfLogo from "images/skillIcons/drf.svg";
import gitHubLogo from "images/skillIcons/git-hub.svg";
import postmanLogo from "images/skillIcons/postman.svg";
import vsCodeLogo from "images/skillIcons/vs-code.svg";
import vercelLogo from "images/skillIcons/vercel.svg";

export interface SkillInfo {
  name: string;
  logo: string;
}

export const skillsList: SkillInfo[] = [
  { name: "JavaScript", logo: javascriptLogo },
  { name: "Python", logo: pythonLogo },
  { name: "TypeScript", logo: typescriptLogo },
  { name: "HTML", logo: htmlLogo },
  { name: "CSS", logo: cssLogo },
  { name: "React", logo: reactLogo },
  { name: "Redux", logo: reduxLogo },
  { name: "React Native", logo: reactNativeLogo },
  { name: "Angular", logo: angularLogo},
  { name: "Django", logo: djangoLogo },
  { name: "NodeJS", logo: nodeJsLogo },
  { name: "DRF", logo: drfLogo},
  { name: "Knex", logo: knexLogo },
  { name: "SQL", logo: sqlLogo },
  { name: "PostgreSQL", logo: postgreSqlLogo },
  { name: "SQLite3", logo: sqliteLogo },
  { name: "MongoDB", logo: mongodbLogo },
  { name: "Cloud Bigtable", logo: bigtableLogo },
  { name: "Firebase", logo: firebaseLogo },
  { name: "Docker", logo: dockerLogo },
  { name: "Git", logo: gitLogo },
  { name: "Postman", logo: postmanLogo},
  { name: "GitHub", logo: gitHubLogo},
  { name: "Pytest", logo: pytestLogo },
  { name: "Jest", logo: jestLogo },
  { name: "d3", logo: d3Logo },
  { name: "LESS", logo: lessLogo },
  { name: "SASS", logo: sassLogo },
  { name: "Material UI", logo: materialUiLogo },
  { name: "VS Code", logo: vsCodeLogo},
  { name: "Vercel", logo: vercelLogo},
  { name: "Agile", logo: agileLogo },
];

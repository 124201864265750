import emailjs from "emailjs-com";
import { Message, blankMessage } from "./fixtures";

enum Errors {
  IncompleteForm = "INCOMPLETE FORM",
  InvalidEmail = "INVALID EMAIL",
}

function validateForm(formData: Message) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (
    formData.name === "" ||
    formData.email === "" ||
    formData.message === ""
  ) {
    return Errors.IncompleteForm;
  } else if (!re.test(String(formData.email).toLowerCase())) {
    return Errors.InvalidEmail;
  } else {
    return true;
  }
}

export function validateAndSendMessage(
  formData: Message,
  resetMessage: (message: Message) => void
) {
  const validationResult: string | boolean = validateForm(formData);

  if (validationResult === Errors.IncompleteForm) {
    alert(INCOMPLETE_FORM_ALERT_TEXT);
  } else if (validationResult === Errors.InvalidEmail) {
    alert(INVALID_EMAIL_ALERT_TEXT);
  } else if (validationResult) {
    emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, USER_ID).then(
      (response) => {
        resetMessage(blankMessage);
        alert(SEND_SUCCESS_ALERT_TEXT);
      },
      (error) => {
        alert(SEND_ERROR_ALERT_TEXT);
      }
    );
  }
}

const INCOMPLETE_FORM_ALERT_TEXT =
  "Please include at lease a name, eamil, and message before submitting.";

const INVALID_EMAIL_ALERT_TEXT =
  "Please use a valid email. (Example: email@example.com)";

const SEND_SUCCESS_ALERT_TEXT =
  "Your message has been recieved! I will be responding shortly.";

const SEND_ERROR_ALERT_TEXT =
  "Opps. Something went wrong when trying to send your message. Please try again or reach out to me on LinkedIn or Twitter.";

const SERVICE_ID = "service_xfiv7lg";
const TEMPLATE_ID = "template_jtp5kvf";
const USER_ID = "user_438guekYdXIfwArsFuL1g";

import styled from "styled-components";
import breakpoints from "global/breakPoints";
import colors from "global/colors";
import Arrow from "components/Arrow";

function AboutSection() {
  return (
    <AboutSectionWrapper id="about">
      <AboutContentWrapper>
        <AboutTitle>{ABOUT_TITLE_TEXT}</AboutTitle>
        <AboutText>{ABOUT_PARAGRAPH_1_TEXT}</AboutText>
        <AboutText>{ABOUT_PARAGRAPH_2_TEXT}</AboutText>
        <AboutText>{ABOUT_PARAGRAPH_3_TEXT}</AboutText>
      </AboutContentWrapper>
      <Arrow backgroundColor={colors.white} polygonColor={colors.green} />
    </AboutSectionWrapper>
  );
}

const AboutSectionWrapper = styled.section`
  align-items: center;
  background-color: ${colors.green};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AboutContentWrapper = styled.div`
  margin: 10rem auto;
  max-width: 80%;
  text-align: center;
  width: 1000px;

  @media (max-width: ${breakpoints.medium}) {
    width: 90%;
  }
`;

const AboutTitle = styled.h2`
  font-size: 3.6rem;

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

const AboutText = styled.p`
  font-size: 2.4rem;
  margin-top: 50px;
  line-height: 3.6rem;
  font-weight: 100;
`;

const ABOUT_TITLE_TEXT = "A Little About Me";

const ABOUT_PARAGRAPH_1_TEXT =
  "For over 10 years, I worked in healthcare as a radiographer. Whether it was working on a trauma team in the emergency room, teaching and training clinical students, or collaborating on cross-functional teams in interventional procedures, I loved performing outstanding services and exams for my patients and care teams. While I was finding success in my field, I wanted to work in an environment where my creativity and drive to learn new technologies and systems would be a greater asset to my professional development.";

const ABOUT_PARAGRAPH_2_TEXT =
  "In late 2019 I decided to make a change and enrolled in BloomTech (at the time known as Lambda School) to learn full-stack web development. On top of software engineering, that experience taught me the value that my creativity and lifestyle of learning could bring.";

const ABOUT_PARAGRAPH_3_TEXT =
  "I've been working as a software engineer ever since and have loved every opportunity to learn a new technology, collaborate to find a solution to a tricky problem, and deliver a feature that makes a real impact on the platform or application I'm working on.";

export default AboutSection;

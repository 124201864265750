import styled from "styled-components";
import Arrow from "components/Arrow";
import FacePic from "images/Glasses.jpg";
import breakpoints from "global/breakPoints";
import colors from "global/colors";
import { Button } from "components/Button";
import ContactIcons from "./contactIcons";

function HomeSection() {
  return (
    <HomeWrapper id="home">
      <HomeContent>
        <PhotoGreeting>
          <Photo src={FacePic} alt={PHOTO_ALT_TEXT} />
          <GreetingWrapper>
            <GreetingText>
              {GREETING_TEXT}
              <Name>{NAME_TEXT}</Name>
            </GreetingText>
            <Title>{TITLE_TEXT}</Title>
          </GreetingWrapper>
        </PhotoGreeting>
        <Blurb>{BLURB_TEXT}</Blurb>
        <StyledButton
          as="a"
          backgroundColor={colors.green}
          textColor={colors.black}
          height={50}
          width={200}
          href={require("documents/Resume_Amos_Rose.pdf")}
          target="_blank"
          rel="noopener noreferrer"
        >
          {BUTTON_TEXT}
        </StyledButton>
        <ContactIcons
          baseColor={colors.blackTransparent}
          hoverColor={colors.black}
        />
      </HomeContent>
      <Arrow backgroundColor={colors.green} polygonColor={colors.white} />
    </HomeWrapper>
  );
}

const HomeWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
`;

const HomeContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const PhotoGreeting = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20vh;
  max-width: 90%;
  width: 800px;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    width: 90%;
  }
`;

const Photo = styled.img`
  border: none;
  border-radius: 100%;
  width: 250px;

  @media (max-width: ${breakpoints.medium}) {
    margin-bottom: 20px;
  }
`;

const GreetingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const GreetingText = styled.h1`
  font-size: 7rem;
  color: ${colors.black};

  @media (max-width: ${breakpoints.small}) {
    font-size: 5rem;
  }
`;

const Name = styled.strong`
  font-weight: 600;
`;

const Title = styled.h2`
  color: ${colors.gray};
  margin-top: 25px;

  @media (max-width: ${breakpoints.small}) {
    font-size: 3.6rem;
  }
`;

const Blurb = styled.p`
  font-size: 2.4rem;
  line-height: 3.6rem;
  margin-top: 50px;
  text-align: center;
  width: 80%;

  @media (max-width: ${breakpoints.small});
`;

const StyledButton = styled(Button)`
  margin: 50px 0;
`;

const PHOTO_ALT_TEXT = "Amos smiling in front of a blue background";

const GREETING_TEXT = "Hi, I'm ";

const NAME_TEXT = "Amos.";

const TITLE_TEXT = "Full-Stack Developer";

const BLURB_TEXT =
  "I enjoy creating things, solving problems, and trying to leave everything I touch a little better than I found it.";

const BUTTON_TEXT = "My Resume";

export default HomeSection;
